import React, { FC } from 'react';

import { usePlaceholderComponentsForRendering } from '@sitecore/common';
import { CarouselRendering } from '@sitecore/types/manual/Carousel';
import { Bleed, Carousel as SparkyCarousel } from '@sparky';

export const Carousel: FC<CarouselRendering> = ({ params, fields }) => {
  const showIndicator = !!Number(params?.showIndicator) as true;
  const maxHeight = params?.maxHeight;
  const carouselItems = usePlaceholderComponentsForRendering('jss-carousel-main');

  return (
    <Bleed horizontal="gridGutter">
      <SparkyCarousel
        scrollMarginX="gridGutter"
        scrollBehavior={showIndicator ? 'native' : { initial: 'snap', md: 'vertical' }}
        hasCardPadding
        startAt={Number(params?.startAt ?? 0)}
        prevLabel={fields?.previousButtonText?.value}
        nextLabel={fields?.nextButtonText?.value}
        showIndicator={showIndicator}
        hideArrows={!!Number(params?.hideArrows)}
        layout={params?.layout ?? 'default'}>
        {carouselItems.map((child: JSX.Element) => {
          if (child?.props?.children?.props?.rendering?.componentName === 'Image' && maxHeight) {
            Object.assign(child?.props?.children?.props?.rendering?.params, { maxHeight });
          }
          return child.type === 'code' ? child : <SparkyCarousel.Item key={child.key}>{child}</SparkyCarousel.Item>;
        })}
      </SparkyCarousel>
    </Bleed>
  );
};

export default Carousel;
